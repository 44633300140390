<template>
<section class="projects" id="projects">
      <h2 class="heading">Mes<span> Projets</span></h2>


</section>
</template>

<script>
export default {

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

:root {
  --bg-color: #2f253a;
  --second-bg-color: #b393dc;
  --text-color: #ededed;
  --main-color: #a68fe1;
  --cyan: #cceaea;
  --salmon: #fcb08c;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  color: var(--text-color);
}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

</style>