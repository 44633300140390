<template>
                      <!-- 1.Header -->

<header>
  <div class="logo">
      <a href="#home" classe="logotxt">Priscylia Beranger. <span class="animate" style="--i:1;"></span></a>
    </div>



<div>
  <!-- MENU BASIC -->
      <nav class="navbar">

        <a href="/#home" class="active">Home</a>
        <a href="#about">About</a>
        <a href="#education">Experiences</a>
        <a href="#skills">Skills</a>
        <a href="#projects">Projects</a>
        <a href="#contact">Contact</a>

        <span class="activ-nav"></span>
        <span class="animate" style="--i:2;"></span>
      </nav>

<!-- MENU BURGER -->
      <div class="bx bx-menu" id="menu-icon"><span class="animate" style="--i:2;"></span>
        <img id="toggle-icon" src="/images/menu.png" alt="" @click="toggleDropDown">
      </div>

</div>


</header>

<div>
  <router-view/>
</div>
              
                          <!-- 2. Section Footer  --> 
  
  <footer class="footer">

    <div class="footer-logo">
      <img src="/images/logomauve.png" alt="">

      <div class="logotxt">
          <p>Copyright 2024 by PriscyliaB.  | Tous droits réservés</p>
        </div>

          <div class="footer-icon">
              <a href="https://www.facebook.com/priscyliaberanger/"><img src="/images/fb.png" alt=""></a>
              <a href="https://www.instagram.com/priscyli_art/"><img src="/images/insta.png" alt=""></a>
              <a href="https://www.linkedin.com/in/priscylia-beranger/"><img src="/images/lkd.png" alt=""></a>
          </div>


    </div>

  </footer>
  
</template>

<script>

import VanillaTilt from 'vanilla-tilt';
  export default {


    data() {
    return {
      ouvertureCv : true,
    };
  },



    mounted() {
      VanillaTilt.init(document.querySelectorAll(".imgHover"), {
        max: 25,
        speed: 400
      });

      VanillaTilt.init(document.querySelectorAll(".box"), {
        max: 15,
        speed: 400,
        glare: true,
        "max-glare": 0.5
      });

   },

    methods: { 

      openCv() {
        this.ouvertureCv = !this.ouvertureCv
      },
      closeCv() {
        this.ouvertureCv = !this.ouvertureCv
      }
  }

}


    

            // ------------------ TOGGLE ICON NAVBAR -------------------------

            // ouvrir le menu au clique sur le menu icon
            // let menuIcon = document.querySelector('#menu-icon');
            // let navbar = document.querySelector('.navbar');

            // menuIcon.onclick = () => {
            //   menuIcon.classList.toggle('bx-x');
            //   navbar.classList.toggle('active');
            // }








            // ------------------ SCROLL SCTIONS -------------------------
      let sections = document.querySelectorAll('section');
      let navLinks = document.querySelectorAll('header nav a');

      window.onscroll = () => {
        sections.forEach(sec => {
          let top = window.scrollY;
          let offset = sec.offsetTop - 100;
          let height = sec.offsetHeight;
          let id = sec.getAttribute('id');

          if (top >= offset && top < offset + height) {
            // ------------------ ACTIVE NAVBAR LINKS -------------------------
            navLinks.forEach(links => {
              links.classList.remove('active');
              document.querySelector('header nav a[href*=' + id + ']').classList.add('active');
            });
          }

        });
          // Sticky header
          let header = document.querySelector('header');

          header.classList.toggle('sticky', window.scrollY > 100);

      }

</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

:root {
  --bg-color: #2f253a;
  --second-bg-color: #b393dc;
  --text-color: #ededed;
  --main-color: #a68fe1;
  --cyan: #cceaea;
  --salmon: #fcb08c;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
}



/* //////////////////////////////////////////////////////// 1.header //////////////////////////////////////////////////////// */
header {
  position: fixed;
  /* top: 0;
  left: 0; */
  width: 100%;
  padding: 2rem 9%;
  background: var(--bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .3s;
}

header.sticky {
  background: var(--bg-color);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.187);
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar {
  position: relative;
  display: flex;
}


.logo a {
  font-size: 2.5rem;
  color: var(--main-color);
  font-weight: 800;
  cursor: pointer;
  text-decoration: none;
  margin-left: 1rem;
  
}
.logo:hover {
  color: var(--main-color);
}

.navbar a {
  font-size: 1.7rem;
  color: var(--text-color);
  margin-left: 4rem;
  font-weight: 600;
  text-decoration: none;
  transition: .3s;
}
.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
}

#menu-icon {
  position: relative;
  font-size: 3.6rem;
  color: var(--text-color);
  display: none;
  cursor: pointer;
}



/* ///////////////////////////////////////////////////////  FOOTER //////////////////////////////////////////////// */

footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--bg-color);
  padding: 1px 40px;
  z-index: 100;
  transition: 0.3s;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.866);
}

.footer-logo{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo img{
  vertical-align: middle;
  width: 5%;
  height: 5%;
}


.footer-icon{
  display: flex;
  right: 0;
  gap: 1rem;
}

.footer-icon img{
  width: 30px;
  height: auto;
}

footer .logotxt{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;

}
footer .logotxt p{

  font-size: 1.5rem;
  color: var(--second-bg-color)

}

/* ANIMATION RELOAD AND SCROLL */

.animate {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  z-index: 98;
}

.logo .animate,
.navbar .animate,
#menu-icon .animate,
.home .show-animate .animate {
  animation: showRight 1s ease forwards;
  animation-delay: calc(.3s * var(--i));
}


/* /////////////////////////////////////////////BREAKPOINTS & MEDIA QUERIES\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

@media (max-width: 1440px) {
  html {
    font-size: 55%;
  }

}

.home-icon {
  bottom: 1rem;
 top: 60rem;
}

.elements.content {
  top: 320px;
  left: initial;
  bottom: 0;
  right: -20px;
  width: 85%;
  height: 45%;
  padding: 20px;

}

@media (max-width: 991px) {

  html {
    font-size: 50%;
  }

  .header {
    padding: 2rem 4%;
  }

  .home {
    padding: 0 4%;
  }

  .about-content h3 {
    margin-right: 20rem;
  }

  .about-content p {
    margin-right: 15rem;
  }


  .footer {
    padding: 2rem 4%;
  }
}

@media (max-width: 768px) {

  html {
    font-size: 45%;
  }

  .header {
    background-color: var(--bg-color);
  }

  #menu-icon {
    display: block;
  }

  .home-icon {
 top: 75rem;
}

  .about h2 {
    margin-top: -2rem;
  }

  .about-container {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    gap: 18rem;
  }

  .about-container .droite {
    display: flex;
    justify-content: center;
    margin-top: -6rem;
  }

  .about-container .gauche {
    display: flex;
    justify-content: center;
    margin-top: -12rem;
    margin: -10rem;
  }

  .about-content {
    width: 90%;
    margin: 0 auto;
  }

  .about-content p {
    font-size: 2.4rem;
    margin : 1rem 4rem 2rem;
  }


  .navbar {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    padding: 1rem 4%;
    background: var(--main-color);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
    z-index: 1;
    transition: .25s ease;
    transition-delay: .25s;
  }

  .navbar .active {
    left: 0;
    transition-delay: 0s;
  }

  .navbar .active-nav {
    position: absolute;
    top: 0;
    left:-100%;
    width: 100%;
    height: 100%;
    background: var(--bg-color);
    border-top: .1rem solid rgba(0, 0, 0, .2);
    z-index: -1;
    transition: .25s ease;
    transition-delay: 0s;
  }

  .navbar.active .active-nav {
    left : 0;
    transition-delay: .25s;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
    transform: translateX(-20rem);
    transition: .25s ease;
    transition-delay: 0s;
  }

  .navbar.active a {
    transform: translateX(0);
    transition-delay: .25s;
  }

  .home-imgHover {
    pointer-events: none;
    background: var(--bg-color);
    opacity: .8;
  }

  .home-content p {
  margin: 1rem;
  margin-right: 10rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
  font-size: 2.5rem;

  }

  .home-content h3 {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  .projects-container {
    margin-top: 10rem;
  }

  .box .elements.content {
    top: 320px;
    left: initial;
    bottom: 0;
    right: -20px;
    width: 85%;
  
}


}



@media (max-width: 520px) {
  html {
    font-size: 40%;
  }


  .home-content h1 {
    display: flex;
    flex-direction: column;
    font-size: 5rem !important;
    margin-left: 6rem;
    margin-right: 8rem;
  }

  .home-content h3 {
    margin: 1rem 0 3rem;
  }

  .home-icon {
    width: 160px;
    bottom: 6rem !important;
  }


  .home-content p {
  margin: 1rem;
  margin-right: 10rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  }

  .model-container {
  min-height: 20rem;
  width: 70%;
  right: 15rem;
  left: 8rem;

}

.contact form  {
  margin-top: -8rem;
}

}




@media (max-width: 462px) {

  html {
    font-size: 45%;
  }
  

  .home-content h1 {
    font-size: 5rem;
    margin-right: 30rem;
  }

  .home-content p {
  margin: 1rem;
  margin-right: 20rem;
  margin-bottom: 1rem;
  }

  .home-icon a {
    margin: 0 1rem;
    bottom: -10rem;
  }

  .about h2 {
    font-size: 5rem;
    margin-top: -12rem;
  }

  .about-content p {
    font-size: 2rem;
    margin : 1rem 2rem 2rem;
    padding-bottom: 2rem;
  }

  .about-img {
  position: relative;
  width: 15rem;
  height: 15rem;

}

.about-content h3   {
  font-size: 2.6rem;
  margin : 4rem;
  margin-top:-1rem;
}

.about-container .droite {
  margin-top: -8rem;
  margin-bottom: -8rem;;
}

  .btn-box {
    flex-wrap: wrap;
    gap: 1rem;
    margin-top:4.5rem;
  }

  .education {
    padding: 10rem 4% 5rem 5%;
  }

  .heading {
    font-size: 6.5rem;
    display: flex;
    flex-direction: column;
  }


  .contact form .input-box .input-field {
    width: 100%;
  }

  .footer {
    flex-direction: column-reverse;
  }

  footer .footer-logo {
  gap: 5rem;
}




}


</style>
