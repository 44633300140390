<template>
  
                        <!-- 2.Home section -->


 <section class="home show-animate" id="home">
    <div class="home-content">
      <h1>Bonjour,</h1><h1> Je suis <span>Priscylia Beranger</span></h1>
      <div class="text-animate">
        <h3>Developpeuse Web </h3>
      </div>
      <p>Je suis une passionnée du numérique et de la programmation.
        Une jeune femme de 35 ans, autodidacte et surtout un véritable couteau suisse puisque que j'ai d'autres corde à mon arc professionnel.
        En effet je suis aussi Illustratrice, graphiste et web designer, une panoplie de services pour réalisér tous vos projets.
      </p>

      <div class="btn-box">

        <a href="#contact" class="btn">Me contacter</a>
        <div class="popupCv">
          <a class="btn" href="/images/cv.pdf" download="Cv_pricyliaBERANGER"> Voir mon cv</a>
        </div>


      </div>

    </div>

    <div class="home-icon">
      <a href="https://www.facebook.com/priscyliaberanger/"><img src="/images/fb.png" alt=""></a>
      <a href="https://www.instagram.com/priscyli_art/"><img src="/images/insta.png" alt=""></a>
      <a href="https://www.linkedin.com/in/priscylia-beranger/"><img src="/images/lkd.png" alt=""></a>
    </div>

      <div class="imgHover">
        <img src="/images/moi.jpg" alt="">
      </div>
  </section>

                      <!-- 3. About section -->


  <section class="about" id="about">
    <h2 class="heading">Qui<span> Suis-Je</span>?</h2>  

    <div class="about-container">
      <div class="droite">
        <div class="about-img">
        <img src="/images/pp.jpg" alt="">
        <span class="circle-spin"></span>
        </div>
      </div>

      <div class="gauche">
        <div class="about-content">
          <h3>Développeuse web passionnée et créative</h3>
          <p>
          Je suis un développeuse web  passionnée par le numérique. Je connais les langages front-end (HTML, CSS, SCSS, Bootstrap, JavaScript, Vue) et back-end (PHP, MySQL) pour créer des sites web et des applications web complets et performants.
          </p>
          <p>
            Je suis également illustratrice et web designer et je peux créer des visuels et des interfaces utilisateur intuitives et attractives. Je suis à l'écoute de vos besoins et je m'engage à vous fournir un travail de qualité dans les délais impartis
          </p>
          <div class="btns-box btns">
            <a href="#" class="btns">Lire plus</a>
          </div>
        </div>
      </div>


  </div>

  </section>

                      <!-- 4. Section Experiences  -->


    <section class="education" id="education">
      <h2 class="heading">Mes<span>Experiences</span></h2>

      <div class="education-row">

        <!-- A.Diplômes & Formations -->
        <div class="education-column">
          <h3 class="title">Diplômes </h3>
          <div class="education-box">

            <div class="education-content">
              <div class="content">
                <div class="year"><img src="/images/calendar.png" alt="">2023 - 2024</div>
                <h3>BTS DWWM - EN COURS</h3>
                <h3>Developpeur Web et Web Mobile - AFPA</h3>
                <p>Maquettage : Figma</p>
                <p>Outils : VsCode, GitHub, Kraken, Laragon, MySQL Workbench, ...</p>
                <p>Front-end : Hmtl, Css, Scss, Js, Bootstrap, Vue</p>
                <p>Back-end : MongoDb, Mysql, Php, Symphony</p>
              </div>
            </div>

            <div class="education-content">
              <div class="content">
                <div class="year"><img src="/images/calendar.png" alt="">2022 - 2023</div>
                <h3>BAC +1 Mention SNO - OBTENU</h3>
                <h3>Services Numériques aux Organisations</h3>
                <p>Graphisme : Figma, Affiches, Flyers, Logos, ..</p>
                <p>Ouitls : Suite Office, Suite Adobe,... </p>
                <p>Web : Site web, Wordpress, Wix, ...</p>
                <p>Communication : Référencement, Rédaction, Réseaux sociaux</p>
              </div>
            </div>

            <!-- <div class="education-content">
              <div class="content">
                <div class="year"><img src="/images/calendar.png" alt="">Depuis 2022 </div>
                <h3>Formations web et Numériques en Ligne </h3>
                <h3>OpenClassRoom</h3>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat in aspernatur, quibusdam consequatur exercitationem a non maiores vitae magnam cupiditate!</p>
              </div>
            </div> -->

            <div class="education-content">
              <div class="content">
                <div class="year"><img src="/images/calendar.png" alt="">2007 - 2008</div>
                <h3>Bac Pro Commerce - OBTENU avec mention</h3>
                <p></p>
              </div>
            </div>



          </div>
        </div>

        <div class="education-column">
          <h3 class="title">Experiences Professionnelles</h3>
          <div class="education-box">

            <div class="education-content">
              <div class="content">
                <div class="year"><img src="/images/calendar.png" alt="">2023 - 2024</div>
                <h3>Developpeuse Web et Web Mobile - STAGE</h3>
                <p>Création d'un site e-commerce en Front-end & Back-end</p>
              </div>
            </div>

            <div class="education-content">
              <div class="content">
                <div class="year"><img src="/images/calendar.png" alt="">2022 - 2023</div>
                <h3>Technicienne Généraliste du Numérique - STAGES</h3>
                <p>Pour trois entreprises de la ville de CHARMES:
                  Créations Numérique (Logos, charte graphique, cartes de visites, visuels, affiches, ...), animation d'atelier numérique, rédaction, refonte du site web et création de widget, gestion et mise à jour de l'application de la ville</p>
              </div>
            </div>

            <div class="education-content">
              <div class="content">
                <div class="year"><img src="/images/calendar.png" alt="">2017</div>
                <h3>Rédactrice Web et Agent Commercial - LSW</h3>
                <p>Pour un hebergeur web : Rédaction d'articles de blog, service technique et commercial</p>
              </div>
            </div>

            <div class="education-content">
              <div class="content">
                <div class="year"><img src="/images/calendar.png" alt="">2014 </div>
                <h3>Web Commerciale & Community Manager - DEDIZONES</h3>
                <p>Pour un hébergeur web : Mise en route des services d'hebergement, gestion des partenariats & évènements, gestion et animation des réseaux sociaux, modération de forum, rédaction d'articles.</p>
              </div>
            </div>


          </div>

        </div>   

      </div>

    </section>

                          <!-- 5. Section Skills  -->

    <section class="skills" id="skills">
      <h2 class="heading">Mes <span>Compétences</span></h2>

      <div class="skills-row">
        <div class="skills-column">
          <h3 class="title">FRONT-END</h3>

          <div class="skills-box">
            <div class="skills-content">
              <div class="progress">
                <h3>HTML <span>90%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>CSS <span>80%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>SCSS <span>70%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>Bootstrap <span>80%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>JavaScript <span>60%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>VueJs <span>60%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

            </div>

          </div>

        </div>

        <div class="skills-column">
          <h3 class="title">BACK-END</h3>

          <div class="skills-box">
            <div class="skills-content">
              <div class="progress">
                <h3>MySql / MySql Workbench<span>70%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>MongoDb <span>60%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>PHP <span>50%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>Symphony <span>50%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

            </div>

          </div>

        </div>

        <div class="skills-column">
          <h3 class="title">TOOLS</h3>

          <div class="skills-box">
            <div class="skills-content">


              <div class="progress">
                <h3>Maquettage : Figma<span>80%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>


              <div class="progress">
                <h3>Design : Canva, Suite ADOBE<span>85%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>Référencement: SEO , SEM , SEA  <span>70%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>


              <div class="progress">
                <h3>Gestion de projet : Trello<span>70%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>GitHub / Git + Kraken<span>70%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>WordPress<span>85%</span></h3>
                <div class="bar">
                  <span></span>
                </div>
              </div>

              <div class="progress">
                <h3>VsCode , Laragon<span>85%</span></h3>
                <div class="bar">
                  <span></span>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="skills-column">
          <h3 class="title">SOFT-SKILLS</h3>

          <div class="skills-box">
            <div class="skills-content">
              <div class="progress">
                <h3 >Communication / Esprit critique<span>80%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>Méthode SCRUM<span>85%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>Autonomie / Esprit d'équipe<span>80%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>Bienveillance / Empathie <span>95%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

              <div class="progress">
                <h3>Motivation / Optimisme <span>85%</span></h3>
                <div class="bar">
                  <span></span>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>
    </section>



                          <!-- 6. Section Projects  -->
    <section class="projects" id="projects">
      <div id="projects">
        <h2 class="heading">Mes<span> Projets</span></h2>
      </div>

      <div class="projects-container">
        <div class="box">
          <div class="elements imgBX">
            <img src="/images/back-end.png" alt="">
            <!-- <div> Icônes conçues par <a href="https://www.freepik.com" title="Freepik"> Freepik </a> from <a href="https://www.flaticon.com/fr/" title="Flaticon">www.flaticon.com'</a></div> -->
          </div>

          <div class="elements title">
            <h2> DEV </h2>
          </div>

          <div class="elements name">
            <h2>Developpement Web</h2>
          </div>

          <div class="elements content">
            <p>Projets réalisés en Developpement Web</p>
            <br>
            <p>Front & Back</p>


            <div class="btns-box btns">
              <router-link to="/devweb" class="btns">ICI</router-link>
            </div>
          </div>

          <div class="card">

          </div>
        </div>

        <div class="box">
          <div class="elements imgBX">
            <img src="/images/web.png" alt="">
            <!-- <div> Icônes conçues par <a href="https://www.flaticon.com/fr/auteurs/srip" title="srip"> srip </a> from <a href="https://www.flaticon.com/fr/" title="Flaticon">www.flaticon.com'</a></div> -->
          </div>

          <div class="elements title">
            <h2> WEB </h2>
          </div>

          <div class="elements name">
            <h2>Sites et Applications</h2>
          </div>

          <div class="elements content">
            <p>Les sites web et applications que j'ai réalisés</p>
            <br>
            <p>DEV & CMS</p>


            <div class="btns-box btns">
              <a href="#" class="btns">ICI</a>
            </div>
          </div>

          <div class="card">

          </div>
        </div>

        <div class="box">
          <div class="elements imgBX">
            <img src="/images/visuelle.png" alt="">
            <!-- <div> Icônes conçues par <a href="https://www.flaticon.com/fr/auteurs/icon-home" title="Icon home"> Icon home </a> from <a href="https://www.flaticon.com/fr/" title="Flaticon">www.flaticon.com'</a></div> -->
          </div>

          <div class="elements title">
            <h2> LOGO </h2>
          </div>

          <div class="elements name">
            <h2>Identité Visuelle</h2>
          </div>

          <div class="elements content">
            <p> Les Identités Visuelles que j'ai créés</p>
            <br>
            <p>Logo & Charte </p>


            <div class="btns-box btns">
              <a href="#" class="btns">ICI</a>
            </div>
          </div>

          <div class="card">

          </div>
        </div>

        <div class="box">
          <div class="elements imgBX">
            <img src="/images/communication.png" alt="">
            <!-- <div> Icônes conçues par <a href="https://www.flaticon.com/fr/auteurs/bzzrincantation" title="BZZRINCANTATION"> BZZRINCANTATION </a> from <a href="https://www.flaticon.com/fr/" title="Flaticon">www.flaticon.com'</a></div> -->
          </div>

          <div class="elements title">
            <h2> COM </h2>
          </div>

          <div class="elements name">
            <h2>Communication Visuelle</h2>
          </div>

          <div class="elements content">
            <p>Projets réalisés dans la Communication</p>
            <br>
            <p>Flyers & Brochures</p>


            <div class="btns-box btns">
              <a href="#" class="btns">ICI</a>
            </div>
          </div>

          <div class="card">

          </div>
        </div>


        <div class="box">
          <div class="elements imgBX">
            <img src="/images/illustration.png" alt="">
            <!-- <div> Icônes conçues par <a href="https://www.flaticon.com/fr/auteurs/ehtisham-abid" title="Ehtisham Abid"> Ehtisham Abid </a> from <a href="https://www.flaticon.com/fr/" title="Flaticon">www.flaticon.com'</a></div> -->
          </div>

          <div class="elements title">
            <h2> DRAW </h2>
          </div>

          <div class="elements name">
            <h2>Illustrations & Affiches</h2>
          </div>

          <div class="elements content">
            <p> Les Illutrations que j'ai réalisées </p>
            <br>
            <p>Illustrations & Affiches</p>


            <div class="btns-box btns">
              <a href="#" class="btns">ICI</a>
            </div>
          </div>

          <div class="card">

          </div>
        </div>
      </div>

    
    </section>



                          <!-- 7. Section Contact  -->                          
                          <section class="contact" id="contact">
    <h2 class="heading">Me<span> Contacter</span></h2>
    
    <form action="#">
      <div class="input-box">
        <div class="input-field">
          <input type="text" placeholder="Nom & Prénom">
          <span class="focus"></span>
        </div>

        <div class="input-field">
          <input type="text" placeholder="Adresse Mail">
          <span class="focus"></span>
        </div>

        </div>

        <div class="input-box">
        <div class="input-field">
          <input type="text" placeholder="Numéro de téléphone">
          <span class="focus"></span>
        </div>

        <div class="input-field">
          <input type="text" placeholder="Sujet du mail">
          <span class="focus"></span>
        </div>

        </div>

        <div class="textarea-field">
          <textarea name="" id="" cols="30" rows="10" placeholder="Ecrivez votre message" required></textarea>
          <span class="focus"></span>
        </div>

        <div class="btns-box btns">
          <button type="submit" class="btns">Envoyer</button>
        </div>
    </form>
  </section>

</template>

<script>

import VanillaTilt from 'vanilla-tilt';
  export default {


    mounted() {
      VanillaTilt.init(document.querySelectorAll(".imgHover"), {
        max: 25,
        speed: 400
      });

      VanillaTilt.init(document.querySelectorAll(".box"), {
        max: 15,
        speed: 400,
        glare: true,
        "max-glare": 0.5
      });

   },


}


            // ------------------ TOGGLE ICON NAVBAR -------------------------







            // ------------------ SCROLL SCTIONS -------------------------
      let sections = document.querySelectorAll('section');
      let navLinks = document.querySelectorAll('header nav a');

      window.onscroll = () => {
        sections.forEach(sec => {
          let top = window.scrollY;
          let offset = sec.offsetTop - 100;
          let height = sec.offsetHeight;
          let id = sec.getAttribute('id');

          if (top >= offset && top < offset + height) {
            // ------------------ ACTIVE NAVBAR LINKS -------------------------
            navLinks.forEach(links => {
              links.classList.remove('active');
              document.querySelector('header nav a[href*=' + id + ']').classList.add('active');
            });
          }

        });
          // Sticky header
          let header = document.querySelector('header');

          header.classList.toggle('sticky', window.scrollY > 100);

      }
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

:root {
  --bg-color: #2f253a;
  --second-bg-color: #b393dc;
  --text-color: #ededed;
  --main-color: #a68fe1;
  --cyan: #cceaea;
  --salmon: #fcb08c;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
}


/* ////////////// 1.header ////////////// */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 9%;
  background: var(--bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  transition: .3s;
}

header.sticky {
  background: var(--bg-color);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.187);
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar {
  position: relative;
  display: flex;
}


.logo a {
  font-size: 2.5rem;
  color: var(--main-color);
  font-weight: 800;
  cursor: pointer;
  text-decoration: none;
  margin-left: 1rem;
  
}
.logo:hover {
  color: var(--main-color);
}

.navbar a {
  font-size: 1.7rem;
  color: var(--text-color);
  margin-left: 4rem;
  font-weight: 600;
  text-decoration: none;
  transition: .3s;
}
.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
}

#menu-icon {
  position: relative;
  font-size: 3.6rem;
  color: var(--text-color);
  display: none;
  cursor: pointer;
}


/* ////////////// 2.Home section design ////////////// */
section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

.home {
  display: flex;
  align-items: center;
  padding: 0 9%;
  background-size: cover;
  background-position: center;
}

.home-content {
  max-width: 60rem;
  z-index: 99;
}

.home-content h1 {
  position: relative;
  display: inline-block;
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.3;
}

.home-content h1 span {
  color: var(--text-color);
}


.home-content .text-animate {
  position: relative;
  width: 32.8rem;
}

.home-content .text-animate h3  {
  font-size: 3.2rem;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: .7px var(--main-color);
  line-height: 5rem;
  letter-spacing: .2rem;
  background-image: linear-gradient(var(--main-color), var(--main-color));
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-position: 0 0;
  animation: homeBGText 4s linear infinite;
  animation-delay: 2s;
}

.home-content .text-animate h3::before {
  content : '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-right: 2px solid var(--main-color);
  z-index: -1;
  animation: homeCursorText 4s linear infinite;
  animation-delay: 2s;
  
}

.home-content p {
  font-size: 2rem;
  margin: 2rem 0 4rem;
}

.btn-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 40rem;
  height: 5rem;
  margin-bottom: 6rem;
  gap: 2rem;
}

.btn-box .btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 100%;
  background: var(--main-color);
  border: .2rem solid var(--main-color);
  border-radius: .8rem;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: .1rem;
  color: var(--bg-color);
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  transition: .5s;
}

.btn-box .btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--bg-color);
  z-index: -1;
  transition: .5s;
}

.btn-box .btn:hover::before {
  width: 100%;
  transition: .3s linear;
}

.btn-box .btn:hover {
  color: var(--main-color);
}

.btn-box .btn:nth-child(2) {
  background: transparent;
  color: var(--main-color);
}

.btn-box .btn:nth-child(2):hover {
  color: var(--bg-color);
}

.btn-box .btn:nth-child(2)::before {
  background: var(--main-color);
}

/* Icon */

.home-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 4rem;
  gap: 2rem;

}

.home-icon a {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height:8rem;
  background: transparent;
  border-radius: 50%;
  font-size: 2rem;
  z-index: 1;
}

.home-icon a:hover {
  transform: scale(1.2);
  transition: .3s;
}

.imgHover {
  position: absolute;
  top: 15rem;
  right: 0;
  width: 45%;
  height: 100%;
  padding-left: 10rem;
}

.imgHover img {
  position: absolute;
  top: 0;
  width: 50%;
  height: 75%;
  opacity: .6;
  object-fit: cover;
  border-radius: 20px;
}

/* .imgHover:hover img{
  background-color: var(--bg-color);
  opacity: .2;
  transition: 1s;
} */


/* ////////////// 3. About section design ////////////// */

.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 8rem;
  background: var(--second-bg-color);
}

.heading {
  font-size: 8rem;
  margin-bottom: 5rem;
  text-align: center;
}

.heading span {
  color: var(--bg-color);
  margin-right: 2rem;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  padding: 8rem;
}


.about-img {
  margin-top: -6rem;
  position: relative;
  width: 35rem;
  height: 35rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-img img {
  width: 90%;
  border-radius: 50%;
  border: .4rem solid var(--bg-color);
}

.about-img .circle-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-top: .4rem solid var(--second-bg-color);
  border-bottom: .4rem solid var(--second-bg-color);
  border-left: .4rem solid var(--bg-color);
  border-right: .4rem solid var(--bg-color);
  animation: aboutSpinner 8s linear infinite;

}

.about-content {
  text-align: center;
  width: 80rem;
}

.about-content h3 {
  margin-top: -6rem;
  font-size: 3.8rem;
  margin-bottom: 40px;
  text-align: center;
  color: var(--bg-color);
}

.about-content p {
  font-size: 2rem;
  margin: 2rem 0 3rem;
  margin-bottom: 40px;
}

.btns-box .btns  {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 5rem;
  background: var(--main-color);
  border: .2rem solid var(--bg-color);
  border-radius: .8rem;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: .1rem;
  color: var(--bg-color);
  z-index: 1;
  overflow: hidden;
  transition: .5s;
}

.btns-box.btns::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--bg-color);
  z-index: -1;
  transition: .5s;
}

.btns-box .btns::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--bg-color);
  z-index: -1;
  transition: .5s;
}

.btns-box .btns:hover::before {
  width: 100%;
  transition: .3s linear;
}

.btns-box .btns:hover {
  background-color: var(--bg-color);
  color: var(--main-color);
}

/* ////////////// 4. Section Education ///////////// */
.education span {
  color: var(--main-color);
  margin-left: 2rem;
}

.education {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 8rem;
}

.education .education-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
}

.education-row .education-column {
  flex: 1 1 40rem;
}

.education-column .title {
  font-size: 2.5rem;
  margin: 0 0 1.5rem 2rem;
}

.education-column .education-box {
  border-left: .2rem solid var(--main-color);
}

.education-box .education-content {
  position: relative;
  padding-left: 2rem;
}

.education-box .education-content::before {
  content: '';
  position: absolute;
  top: 2rem;
  left: -1.1rem;
  width: 2rem;
  height: 2rem;
  background: var(--main-color);
  border-radius: 50%;
}

.education-content .content {
  position: relative;
  padding: 1.5rem;
  border: .2rem solid var(--main-color);
  border-radius: .6rem;
  margin-bottom: 2rem;
  overflow: hidden;
}

.education-content .content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--second-bg-color);
  opacity: .3;
  z-index: -1;
  transition: .5s;
}

.education-content .content:hover::before {
  width: 100%;
  height: 100%;
}

.education-content .content .year {
  font-size: 2rem;
  font-weight: bold;
  color: var(--text-color);
  padding-bottom: .5rem;
}

.year img {
  width: 1.8rem;
  margin-right: .6rem;
  margin-top: .5rem;
}

.education-content .content h3 {
  font-size: 2rem;
  margin-top: .5rem;
  color: var(--main-color);
}

.education-content .content p {
  font-size: 1.6rem;
  margin-top: .5rem;
}


/* ////////////// 5. Section Skils ////////////// */
.skills {
  background-color: var(--second-bg-color);
  min-height: auto;
  padding-bottom: 10rem;
  margin-top: 6rem;
  padding-top: 12rem;
}

.skills .skills-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
}

.skills-row .skills-column {
  flex: 1 1 30rem;
}

.skills-column .title {
  font-size: 2.5rem;
  font-weight: 900;
  margin: 0 0 1.5rem 2rem;
  color: var(--bg-color);
}

.skills-box .skills-content {
  position: relative;
  border: .2rem solid var(--bg-color);
  border-radius: .6rem;
  padding: .5rem 1.5rem;
  z-index: 1;
  overflow: hidden;
}

.skills-box .skills-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: var(--bg-color);
  opacity: .4;
  z-index: -1;
  transition: .5s;
}

.skills-box .skills-content:hover::before {
  height: 100%;
}

.skills-content .progress {
  padding: 1rem 0;
}

.skills-content .progress h3 {
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;
}

.skills-content .progress h3 span {
  color: var(--bg-color);
}

.skills-content .progress .bar {
  width: 100%;
  height: 2.5rem;
  border-radius: .6rem;
  border: .2rem solid var(--bg-color);
  padding: .5rem;
  margin-top: .6rem;
  overflow: hidden;
}

.skills-content .progress .bar span {
  display: block;
  height: 100%;
  background-color: var(--bg-color);
  border-radius: .3rem;
}
/* Progress bar FRONT-END */
.skills-column:nth-child(1) .skills-content .progress:nth-child(1) .bar span {
  width: 90%;
}

.skills-column:nth-child(1) .skills-content .progress:nth-child(2) .bar span {
  width: 80%;
}

.skills-column:nth-child(1) .skills-content .progress:nth-child(3) .bar span {
  width: 70%;
}
.skills-column:nth-child(1) .skills-content .progress:nth-child(4) .bar span {
  width: 80%;
}
.skills-column:nth-child(1) .skills-content .progress:nth-child(5) .bar span {
  width: 60%;
}
.skills-column:nth-child(1) .skills-content .progress:nth-child(6) .bar span {
  width: 60%;
}

/* Progress bar BACK-END */
.skills-column:nth-child(2) .skills-content .progress:nth-child(1) .bar span {
  width: 70%;
}
.skills-column:nth-child(2) .skills-content .progress:nth-child(2) .bar span {
  width: 60%;
}
.skills-column:nth-child(2) .skills-content .progress:nth-child(3) .bar span {
  width: 50%;
}
.skills-column:nth-child(2) .skills-content .progress:nth-child(4) .bar span {
  width: 50%;
}

/* Progress bar TOOLS */
.skills-column:nth-child(3) .skills-content .progress:nth-child(1) .bar span {
  width: 85%;
}
.skills-column:nth-child(3) .skills-content .progress:nth-child(2) .bar span {
  width: 70%;
}
.skills-column:nth-child(3) .skills-content .progress:nth-child(3) .bar span {
  width: 80%;
}
.skills-column:nth-child(3) .skills-content .progress:nth-child(4) .bar span {
  width: 70%;
}
.skills-column:nth-child(3) .skills-content .progress:nth-child(5) .bar span {
  width: 70%;
}
.skills-column:nth-child(3) .skills-content .progress:nth-child(6) .bar span {
  width: 85%;
}
.skills-column:nth-child(3) .skills-content .progress:nth-child(7) .bar span {
  width: 85%;
}

/* Progress bar SOFT-SKILLS */
.skills-column:nth-child(4) .skills-content .progress:nth-child(1) .bar span {
  width: 80%;
}
.skills-column:nth-child(4) .skills-content .progress:nth-child(2) .bar span {
  width: 80%;
}
.skills-column:nth-child(4) .skills-content .progress:nth-child(3) .bar span {
  width: 95%;
}
.skills-column:nth-child(4) .skills-content .progress:nth-child(4) .bar span {
  width: 85%;
}


/* ////////////// 6. Section Projects ////////////// */
.projects {
  min-height: auto;
  padding-bottom: 7rem;
}

.projects .heading {
  margin-top: 20px;
}
.projects .heading span {
  color: var(--main-color);
}

.projects-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10rem;
  margin-top: 5rem;
  margin-bottom: 15rem;
}


.box {
  position: relative;
  border-radius: 20px;
  width: 25rem;
  min-height: 50rem;
  transform-style: preserve-3d;
}

.box .card {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  width: 25rem;
  min-height: 50rem;
  backdrop-filter: blur(10px);
  border: 0.2rem solid rgba(255, 255, 255, 0.5);
  border-top: 0.2rem solid rgba(255, 255, 255, 0.75);
  border-left: 0.2rem solid rgba(255, 255, 255, 0.75);
  border-radius: 20px;
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.5);
  transform-style: preserve-3d;

}

.elements {
  position: absolute;
  top: 80px;
  left: -30px;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.1);
  transform: translateZ(80px);
  backdrop-filter: blur(10px);
  border: 0.2rem solid rgba(255, 255, 255, 0.5);
  border-top: 0.2rem solid rgba(255, 255, 255, 0.75);
  border-left: 0.2rem solid rgba(255, 255, 255, 0.75);
  border-radius: 10px;
}

.elements img {
  top: 0;
  left:initial;
  right: 60px;
  width: 100px;
  height: 100px;
  padding: 10px;
  transform: translateZ(120px);
}

.elements.title {
  top: 50px;
  left:initial;
  right: -10px;
  width: 150px;
  height: 150px;
  padding: 10px;
  transform: translateZ(120px);
}

.elements.title h2 {
  font-size: 4rem;
  margin-top: 40px;
  color: var(--text-color);
  text-align: center;
}

.elements.name {
  top: 210px;
  left:initial;
  right: -30px;
  width: 120%;
  text-align: center;
  /* background-color: yellow; */
  padding: 10px;
  transform: translateZ(100px);
  background: transparent;
  margin-top: 1.5rem;
  backdrop-filter: blur(0px);
  border: none;
  box-shadow: none;
  color: var(--text-color);
  font-size: 1.8rem;
  transition: 0.5s;
  opacity: 1;
  transition-delay: 0.25s;
  
}

.elements.content {
  top: initial;
  left: initial;
  bottom: 0;
  right: -20px;
  width: 65%;
  min-height: 60px !important;
  padding: 20px;


}

.elements.content p {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-align: center;
}

.box .btns-box.btns a {
  margin-left: -16px !important;
}


/* ////////////// 5. Section Contact ////////////// */

.contact {
  min-height: auto;
  padding-bottom: 10rem;
  background: var(--second-bg-color);
}

.contact form {
  max-width: 70rem;
  margin: 0 auto;
  text-align: center;
  padding-top: 6rem;
}

.contact form .input-box { 
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box .input-field {
  position: relative;
  width: 49%;
  margin: 1rem 0;
}

.contact form .input-box .input-field input, 
.contact form .textarea-field textarea {
  width: 100%;
  height: 100%;
  padding: 2rem;
  font-size: 1.6rem;
  color: var(--bg-color);
  background: transparent;
  border-radius: .6rem;
  border: .2rem solid var(--bg-color);
}

.contact form .input-box .input-field input::placeholder, 
.contact form .textarea-field textarea::placeholder {
  color: var(--text-color);
}


.contact form .textarea-field {
  position: relative;
  margin: .8rem 0 4.7rem;
  display: flex;
}

.contact form .textarea-field textarea {
  resize: none;
}

.contact form .btns-box.btns .btns {
  cursor: pointer;
}

/* /////////////////////////////////////////////BREAKPOINTS & MEDIA QUERIES\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

@media (max-width: 1440px) {
  html {
    font-size: 55%;
  }

  .home {
    margin-top: 6rem;
    margin-bottom: 10rem;
  }

  .home h1 {
    font-size: 5rem;
  }

}

.home-icon {
  bottom: 1rem;
 top: 55rem;
}

.elements.content {
  top: 320px;
  left: initial;
  bottom: 0;
  right: -20px;
  width: 85%;
  height: 45%;
  padding: 20px;

}

@media (max-width: 991px) {

  html {
    font-size: 50%;
  }

  .header {
    padding: 2rem 4%;
  }

  .home {
    padding: 0 4%;
  }

  .home-content {
    margin-top: -2rem;;
  }
  .home h1 {
    font-size: 6rem;
    padding-right: 15rem;
    margin-bottom: 3rem;
  }

  .home h3 {
    margin-bottom: 3rem;
  }

  .home p {
    font-size: 2.5rem;
    padding-right: 15rem;
    margin-bottom: 5rem;
  }

  .home-icon {
  bottom: 1rem;
 top: 100rem;
}

  .about h2 {
    margin-top: -5rem;
  }

  .about-container {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    gap: 18rem;
  }

  .about-container .droite {
    display: flex;
    justify-content: center;
    margin-top: -6rem;
  }

  .about-container .gauche {
    display: flex;
    justify-content: center;
    margin-top: -12rem;
    margin: -12rem;
  }

  .gauche h3 {
    margin-top: 1rem;
   margin-right: 3rem;
   margin-left: 2rem;
   font-size: 5rem;
   margin-bottom: 6rem;;
  }

  .about-content {
    width: 90%;
    margin: 0 auto;
  }

  .about-content p {
    font-size: 3rem;
    margin : 1rem 4rem 4rem;
    margin-bottom: 6rem;
  }


  .footer {
    padding: 2rem 4%;
  }
}

@media (max-width: 768px) {

  html {
    font-size: 40%;
  }

  .header {
    background-color: var(--bg-color);
  }

  #menu-icon {
    display: block;
  }

  .home {
    margin-top: -10rem;
  }

  .home-icon {
 top: 65rem;
}

  .about h2 {
    margin-top: -2rem;

  }

  .about-container {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    gap: 18rem;
  }

  .about-container .droite {
    display: flex;
    justify-content: center;
    margin-top: -6rem;
  }

  .about-container .gauche {
    display: flex;
    justify-content: center;
    margin-top: -12rem;
    margin: -12rem;
  }

  .gauche h3 {
    margin-top: 1rem;
   margin-right: 3rem;
   margin-left: 2rem;
  }

  .about-content {
    width: 90%;
    margin: 0 auto;
  }

  .about-content p {
    font-size: 2.4rem;
    margin : 1rem 4rem 4rem;
  }




  .navbar {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    padding: 1rem 4%;
    background: var(--main-color);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
    z-index: 1;
    transition: .25s ease;
    transition-delay: .25s;
  }

  .navbar .active {
    left: 0;
    transition-delay: 0s;
  }

  .navbar .active-nav {
    position: absolute;
    top: 0;
    left:-100%;
    width: 100%;
    height: 100%;
    background: var(--bg-color);
    border-top: .1rem solid rgba(0, 0, 0, .2);
    z-index: -1;
    transition: .25s ease;
    transition-delay: 0s;
  }

  .navbar.active .active-nav {
    left : 0;
    transition-delay: .25s;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
    transform: translateX(-20rem);
    transition: .25s ease;
    transition-delay: 0s;
  }

  .navbar.active a {
    transform: translateX(0);
    transition-delay: .25s;
  }

  .home-imgHover {
    pointer-events: none;
    background: var(--bg-color);
    opacity: .8;
  }

  .home-content {
    margin-top: 10rem;
  }

  .home-content p {
  margin: 1rem;
  margin-right: 15rem;
  margin-bottom: 4rem;
  margin-top: 3.5rem;
  font-size: 2.2rem;

  }

  .home-content h3 {
    margin-bottom: 4rem;
    margin-top: 3rem;
  }

  .projects-container {
    margin-top: 10rem;
  }

  .box .elements.content {
    top: 320px;
    left: initial;
    bottom: 0;
    right: -20px;
    width: 85%;
  
}


}



@media (max-width: 540px) {
  html {
    font-size: 40%;
  }

  .home-content {
    margin-top: 25rem;
    margin-bottom: 6rem;;
  }

  .home-content h1 {
    display: flex;
    flex-direction: column;
    font-size: 4rem !important;
    margin-left: 2rem;

  }

  .home-content h3 {
    margin: 1rem 0 3rem;
  }

  .home-icon {
    width: 160px;
    bottom: 6rem !important;
    top: 88rem;
  }


  .home-content p {
  margin: 1rem;
  margin-right: 3.5rem;
  margin-bottom: 3rem;
  font-size: 2rem;
  }

  .model-container {
  min-height: 20rem;
  width: 70%;
  right: 15rem;
  left: 8rem;

}

.about h2 {
  font-size: 6rem;
}

.education h2 {
  font-size: 6rem;
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.skills h2 {
  font-size: 6rem;
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.projects .heading h2 {
  font-size: 6rem;
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.contact form  {
  margin-top: -8rem;
  margin-bottom: 5rem;
}

}




@media (max-width: 462px) {

  html {
    font-size: 45%;
  }
  
  .home-content {
    margin-top: 25rem;
    margin-bottom: 10rem;;
  }
  .home-content h1 {
    font-size: 3.5rem !important;
    margin-right: 10rem;
  }

  .home-content h3 {
    font-size: 2.5rem !important;
  }

  .home-content p {
  margin: 1rem;
  margin-right: rem;
  margin-bottom: 1rem;
  }

  .home-icon a {
    margin: 0 1rem;
    bottom: -7rem;
  }

  .about h2 {
    font-size: 5rem;
    margin-top: -5rem;
  }

  .about-content p {
    font-size: 2rem;
    margin : 1rem 2rem 2rem;
    padding-bottom: 2rem;
  }

  .about-img {
  position: relative;
  width: 15rem;
  height: 15rem;

}

.about-content h3   {
  font-size: 2.6rem;
  margin : 4rem;
  margin-top:-1rem;
}

.about-container .droite {
  margin-top: -8rem;
  margin-bottom: -8rem;;
}

  .btn-box {
    flex-wrap: wrap;
    gap: 1rem;
    margin-top:4.5rem;
  }

  .about h2 {
  font-size: 4rem;
}

.education h2 {
  font-size: 4rem;
  margin-top: -2rem;
  margin-left: 2rem !important;
}

.skills h2 {
  font-size: 4rem;
  margin-top: -6rem;
  margin-left: -1rem;
}

.projects #projects h2 {
  font-size: 4rem !important;
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.contact h2 {
  font-size: 4rem;
  margin-top: -6rem;
  margin-left: -1rem;
}


  .contact form .input-box .input-field {
    width: 100%;
  }

  .footer {
    flex-direction: column-reverse;
  }

  footer .footer-logo {
  gap: 5rem;
}


}

@media (max-width: 360px) {
  html {
    font-size: 45%;
  }
  

  .home-icon a {
    padding-right: -10rem !important;
    bottom: -7rem;
    margin-left: -0.5rem;
  }
  
  .home-content {
    margin-top: 25rem;
    margin-bottom: 10rem;;
  }
  .home-content h1 {
    font-size: 3.5rem !important;
    margin-right: 10rem;
  }

  .home-content h3 {
    font-size: 2.3rem !important;
  }

.about p {
  font-size: 2rem;
  margin-left: 4rem;
  margin-right: 4rem;
}

.education h2 {
  font-size: 3rem;
  margin-top: -2rem;
  margin-left: 2rem !important;
}

.skills h2 {
  font-size: 3rem;
  margin-top: -6rem;
  margin-left: -1rem;
}

}

/* KEYFRAMES ANIMATIONS */

@keyframes homeBGText {
    0%, 10%, 100% {
      background-position: -33rem 0;
    }

    65%,85% {
      background-position: 0 0;

  }
}

@keyframes homeCursorText {
    0%, 10%, 100% {
      width: 0;
    }

    65%,78%,85% {
      width: 100%;
      opacity: 1;
  }

    75%, 81% {
      opacity: 0;
    }
}

@keyframes aboutSpinner {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes showRight {
  100% {
    width: 0;
  }
}

</style>
